const appConfig = {
    // apiPrefix: 'https://api.badelenaya.com:4000',
    apiPrefix: 'https://api.badeelenaya.com/',
    // apiPrefix: 'http://127.0.0.1:4000',
    // apiPrefix: 'https://ec2-51-20-27-124.eu-north-1.compute.amazonaws.com:4000',
    // apiPrefix: 'https://skin-scanner.onrender.com',
    authenticatedEntryPath: '/app/sales/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
